

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/mediation-objectif-cout-rapprocher-parties-avocat-mediateur.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost42 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Médiation, négociation et arbitrage : comment éviter de se retrouver devant les tribunaux! - Soumissions Avocat"
        description="Le système de justice québécois étant ce qu’il est, il est extrêmement difficile et pénible d’obtenir un jugement favorable dans délais raisonnables. Même lorsqu’un juge tranche en votre faveur, s’il le fait plusieurs mois après les faits, il se peut très bien que la situation soit devenue impertinente. Bien que LIRE PLUS"
        image={LeadImage}>
        <h1>Médiation, négociation et arbitrage : comment éviter de se retrouver devant les tribunaux!</h1>

					
					
						<p>Le système de justice québécois étant ce qu’il est, il est extrêmement difficile et pénible d’obtenir un jugement favorable dans délais raisonnables. Même lorsqu’un juge tranche en votre faveur, s’il le fait plusieurs mois après les faits, il se peut très bien que la situation soit devenue impertinente. Bien que des dommages-intérêts soient envisageables, il faut toujours considérer les frais extrajudiciaires rattachés aux services de la cour ainsi que les honoraires d’avocats.</p>
<p><StaticImage alt="mediation-objectif-cout-rapprocher-parties-avocat-mediateur" src="../images/mediation-objectif-cout-rapprocher-parties-avocat-mediateur.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Bref, même lorsque la justice suit son cours et vous donne raison, le dénouement n’est pas toujours satisfaisant.  C’est d’ailleurs la raison pour laquelle le gouvernement, conscient de ce problème, fait la promotion des modes privés de règlement de différend tells que l’arbitrage, la négociation et la médiation. Ces mécanismes sont des alternatives au procès traditionnel et pourraient très bien régler votre litige!</p>
<h2>Les modes privés de règlement de différends</h2>
<p>Il existe un principe voulant que la justice soit une affaire publique qui soit administrée aux vus et sus de tous. Il s’agit d’une philosophie que tous les grands systèmes judiciaires du monde similaires au nôtre partagent. Toutefois, lorsque la situation ne concerne que des relations entre personnes privées, il n’y a pas de raison de ne pas régler le problème entre les individus en question et personne d’autre.</p>
<p>De plus, le gouvernement est bien au fait des lacunes du système de justice à entendre des causes et à rendre des jugements dans des délais raisonnables. C’est d’ailleurs pour cette raison qu’il fait la promotion des modes privés de règlement depuis plusieurs années.</p>
<p>D’ailleurs, il est maintenant prévu dans le Code de procédure civile du Québec une obligation pour les parties à un litige de considérer le recours aux modes privés de règlement de différend avant de s’adresser au tribunal. Ces efforts devront être mentionnés dans le protocole de l’instance, mais vous conviendrez qu’il n’est pas si simple de faire la preuve que ces modes ont été considérés.</p>
<p><strong>Quand est-il pertinent de considérer les modes privés? </strong>Lorsque vous et votre contrepartie au litige êtes de bonne foi et que vous agissez tous les deux dans un esprit de coopération. En effet, contrairement à la justice publique qui ne nécessite que la volonté de l’une des parties les modes privés requièrent l’accord des deux.</p>
<p><strong> Vous verrez que l’aide d’un avocat pour le règlement de différend au privé est tout aussi pertinente. Il s’agit d’ailleurs d’une expertise en effervescence dans le milieu du droit!</strong></p>
<h2>La négociation avec l’aide d’un avocat</h2>
<p>La négociation est un principe fondamental du règlement de toute dispute, surtout lorsqu’un point de droit est soulevé. Puisque des intérêts divergents sont en jeu, il est normal que chacune des parties argumente pour faire valoir son point. En prenant l’initiative de négocier en dehors d’une salle de cours « a l’amiable », vous pourriez sauver temps et argent.</p>
<p>En effet, la négociation comme mode privé n’est assujettie à aucune règle particulière. Les parties peuvent négocier par eux-mêmes ou par l’entremise des services d’un avocat. Il s’agit évidemment d’une démarche de collaboration : les deux parties devront faire leur bout de chemin pour que la démarche ait une conclusion positive.</p>
<p><strong>Quelle est la valeur de l’entente conclue?</strong> L’entente de négociation prendra la forme d’un contrat, ce qui signifie qu’elle aura la même valeur et qu’elle engendra les mêmes obligations qu’un contrat conclu entre deux personnes. Toutes les dispositions convenues devront donc être respectées.</p>
<p><strong>Les causes pouvant bénéficier de la négociation :</strong> Certaines situations se prêtent plus à négociation que d’autres en raison de leur complexité ou de leur nature. Voici les causes qui se prêtent bien à la négociation :</p>
<ul>
<li>Troubles de voisinage</li>
<li>Problèmes avec des organismes publics</li>
<li>Disputes d’affaires</li>
<li>Différend en milieu de travail</li>
</ul>
<h2>La médiation : rapprocher les intérêts des parties</h2>
<p>Qu’est-ce qui différencie la négociation de la médiation? L’intervention d’un tiers qui vient aider au règlement du litige! Bien que chacune des parties à un différend soit représentée par des avocats défendant leur position, le processus implique également l’intervention d’un médiateur qui tente de rapprocher les intérêts divergents de parties et de trouver un terrain d’entente.</p>
<p><StaticImage alt="mediation-arbitrage-negociation-services-avocats-eviter-proces" src="../images/mediation-arbitrage-negociation-services-avocats-eviter-proces.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>On qualifie souvent le médiateur de « facilitateur », en ce sens que, même s’il ne possède aucun pouvoir décisionnel, son intervention permet de diminuer l’animosité, d’écarter la mauvaise foi et de concentrer les efforts sur les points qui comptent réellement.</p>
<p><strong>Combien coûtent les services de médiation?</strong> À moins que vous ne prévoyiez une entente différente avec votre contrepartie au différend, les frais de la médiation seront défrayés en parts égales. Les frais de la médiation incluent les honoraires du médiateur, les coûts de déplacement, de location de salle appropriée, d’expertise et même d’homologation lorsque vous voulez donner une valeur égale à un jugement à votre entente.</p>
<p><strong>En quoi consiste la médiation familiale? </strong>La médiation familiale est un service offert gratuitement par le Ministère de la Justice aux couples en situation de divorce ou de séparation lors de laquelle des enfants sont impliqués. Elle vise à régler de façon civilisée et consciencieuse les aspects de la séparation tels que la garde des enfants, la pension alimentaire, le partage des régimes matrimoniaux et du patrimoine familial, etc.</p>
<p><strong>Les causes pouvant bénéficier de la médiation :</strong> Certaines causes se prêtent mieux à la médiation que d’autres. Voici les situations qui se prêtent bien à la médiation :</p>
<ul>
<li>Les disputes de succession</li>
<li>Les vices cachés</li>
<li>Les situations de divorce</li>
<li>Problèmes entre actionnaires</li>
</ul>
<h2>L’arbitrage : l’alternative au procès traditionnel</h2>
<p>L’arbitrage est le mode privé de prévention et de règlement de différend qui ressemble le plus au procès traditionnel. En effet, le processus consiste à remettre entre les mains d’une tierce personne la responsabilité de trancher le litige. Celle-ci a donc le rôle de juge entre les mains et a d’importants pouvoirs décisionnels.</p>
<p>Non seulement l’arbitre est-il en mesure de décider l’issue de la dispute, mais il peut également fixer le montant des dommages-intérêts à payer. Il s’agit là d’une importante prérogative, surtout lorsqu’on considère que les décisions de l’arbitre sont sans appel, contrairement aux jugements des tribunaux.</p>
<p><strong>Qui sera nommé comme arbitre? </strong>Il est possible de nommer n’importe quelle personne dans le rôle d’arbitre et aucune disposition légale n’exige une formation quelconque. Cependant, des formations en arbitrage existent bel et bien et solliciter un professionnel avec un tel bagage est un atout non-négligeable qui favorisera un dénouement heureux.</p>
<p>D’ailleurs, il existe des registres d’arbitres qualifiés et accrédités pour permettre aux citoyens de choisi des experts qualifiés. Comme les arbitres ont des responsabilités importantes d’application du droit, il ne faut pas choisir n’importe qui, surtout quand on sait que l’arbitre jouit d’une immunité dans le cadre de ses actions et ses décisions.</p>
<p><strong>Les causes appropriées pour l’arbitrage :</strong> L’arbitrage a l’avantage d’être plus rapide et souvent plus efficace que le procès traditionnel. Vous n’avez pas à respecter les innombrables formalités légales menant au procès et à attendre les délais sans fin. Voici les causes se prêtant bien à l’arbitrage :</p>
<ul>
<li>Les disputes d’affaires et de commerce</li>
<li>Les différends dans le milieu de la construction</li>
<li>Matières de propriété intellectuelle</li>
<li>La violation/non-respect d’un contrat</li>
</ul>
<p><strong>Les limites de et de l’arbitrage.</strong> Si l’arbitrage peut bien être la solution idéale pour régler votre problème et éviter un litige, la loi impose tout de même des limites au mécanisme. En effet, certaines causes ne pourront tout simplement pas être soumises à l’arbitrage en vertu de la loi.</p>
<p>Notamment, il est interdit de soumettre les disputes familiales à l’arbitrage lorsqu’elles concernent le versement d’une pension alimentaire, un jugement en divorce, la séparation des biens des régimes matrimoniaux, etc. Cela est tout aussi vrai en matière de majeurs inaptes et des règles qui s’appliquent à leurs régimes de protection.</p>
<p>L’arbitrage ne peut avoir lieu que lorsque la démarche est entamée du commun accord des parties. Le <strong>consentement</strong> doit être donné par chacune des parties soit au moment de régler le litige ou même avant qu’un le survienne. En effet, dans ce dernier cas, le consentement peut être donné dans une clause contractuelle prévoyant qu’en cas de désaccord, c’est en arbitrage et non en cour que le problème sera tranché.</p>
<p>Il existe également des situations lors desquelles c’est la loi qui impose d’avoir recours à l’arbitrage. Certains milieux de travail sont d’ailleurs forcés d’aller dans une telle direction, tels que : les disputes en matière de relations de travail, les différends dans le milieu du transport, etc.</p>
<h2>Quels sont les avantages et les inconvénients d’opter pour un mode privé?</h2>
<p>Pour que ces modes privés soient mis en place et même encouragés par les acteurs de la justice publique québécoise, c’est qu’il y a quelques avantages à les considérer! Comme chaque situation est unique en son genre, ce sera à vous de voir si vous êtes mieux servis auprès de la justice traditionnelle ou dans le cadre de l’arbitrage ou la médiation!</p>
<p><StaticImage alt="avantages-inconvenients-mediation-limites-familial-dispendieux-avocat" src="../images/avantages-inconvenients-mediation-limites-familial-dispendieux-avocat.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Avantages : </strong></p>
<p><strong>Rapidité</strong> : Comme l’arbitrage et la médiation ne concernent que les individus, il n’est pas nécessaire d’attendre après qui que ce soit pour obtenir justice. Le processus évolue donc à la vitesse convenue par les parties.</p>
<p><strong>Consentement</strong> : Si vous êtes poursuivis au civil, vous n’aurez pas le choix de vous présenter au tribunal pour vous défendre. Cette contrainte n’existe pas avec les modes privés, car ils nécessitent un consentement de chacune des parties.</p>
<p><strong>Coopération</strong> : Comme tous doivent consentir aux modes privés, on y retrouve un esprit de coopération et de bonne foi qui est pour la plupart absent des litiges publics.</p>
<p><strong>Inconvénients :</strong></p>
<p><strong>Dispendieux</strong> : Toute initiative du monde privé vient avec des frais. Les médiateurs et les arbitres étant des professionnels du droit, leur expertise peut coûter cher.</p>
<p><strong>Limités</strong> : Toutes les disputes ne se qualifient pas pour l’arbitrage et la médiation. Il se peut que vous soyez contraints à recourir à la justice publique.</p>
<p><strong>Informel</strong> : Si le manque de formalité peut être un avantage lorsque la situation s’y prête, cela peut également retirer des protections prévues par la loi. Il est parfois mieux de bénéficier de la protection de la loi, même si cela implique subir les inconvénients de délais.</p>
<h2>Soumissions Avocat peut vous aider à trouver un avocat médiateur ou qualifié en arbitrage!</h2>
<p>La justice privée et le règlement de différend via des modes alternatifs sont les voies de l’avenir. Ils permettent tous deux d’éviter des délais interminables que la justice publique fait subir à tous ses intervenants. Pour trouver l’avocat qualifié en arbitrage ou en médiation, laissez Soumission Avocat vous aider!</p>
<p><strong>Vous n’avez qu’à remplir le formulaire en bas de page pour que nous acheminions votre demande à 3 avocats sans tarder!</strong></p>
<p><strong>N’hésitez pas, nos services sont gratuits en plus d’être efficaces, alors contactez-nous!</strong></p>
    </SeoPage>
)
export default BlogPost42
  